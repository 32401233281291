import React from "react"

import Loadable from "react-loadable-visibility/react-loadable"
import Loading from "../components/Otros/Loading/Loading"

const LoadableComponent = React.memo(
  Loadable({
    loader: () => import("../prepages/Servicios"),
    loading: Loading,
  })
)

const servicios = () => {
  return <LoadableComponent />
}

export default React.memo(servicios)
